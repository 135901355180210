@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  outline: none;
  box-sizing: border-box;
  font-family: sans-serif;
  height: max-content;
}

body::-webkit-scrollbar {
  width: 15px;
}

body::-webkit-scrollbar-thumb {
  background-color: black;
  border-radius: 15px;
}

body::-webkit-scrollbar-thumb:hover {
  cursor: pointer;
}

.container {
  margin: 0 auto;
  max-width: 1500px !important;
}